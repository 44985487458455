<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('fertilizerConfig.opening_balance_info')}}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <div class="tableFixHead col-12">
                                    <table class="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th rowspan="2" style="width: 7%;vertical-align:middle">{{$t('globalTrans.sl_no')}}</th>
                                                <th rowspan="2" style="text-align:center;vertical-align:middle">{{$t('fertilizerConfig.name_of_region')}}</th>
                                                <th rowspan="2" style="text-align:center;vertical-align:middle">{{$t('fertilizerConfig.godown_name')}}</th>
                                                <th rowspan="2" style="text-align:center;width:15%;vertical-align:middle">{{$t('fertilizerConfig.opening_date')}}</th>
                                                <th rowspan="2" style="text-align:center;vertical-align:middle">{{$t('fertilizerConfig.godown_information.capacity_in_metric_ton')}}</th>
                                                <th colspan="3" style="text-align:center;vertical-align:middle">
                                                    {{$t('fertilizerConfig.fertilizer_name') + '[' + $t('fertilizerSales.m_ton') + ']' }}<hr/>
                                                    <div style="width:33%;float:left;text-align:center;vertical-align:middle" v-for="(fertilizerName, index1) in fertilizerNameList" :key="index1">
                                                        {{ ($i18n.locale==='bn') ? fertilizerName.text_bn  :  fertilizerName.text_en  }}
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data, index2) in datas" :key="index2">
                                                <td>{{$n(index2+1)}}</td>
                                                <td>{{ ($i18n.locale==='bn') ? data.region_name_bn  :  data.region_name  }}</td>
                                                <td>{{ ($i18n.locale==='bn') ? data.name_bn  :  data.name  }}</td>
                                                <td>
                                                    <flat-pickr :id="'from_date'+index2" v-model="data.opening_date" class="form-control"></flat-pickr>
                                                </td>
                                                <td>{{$n(data.capacity)}}</td>
                                                <td>
                                                    <b-form-input
                                                        v-model="data.tsp"
                                                    ></b-form-input>
                                                </td>
                                                <td>
                                                    <b-form-input
                                                        v-model="data.mop"
                                                    ></b-form-input>
                                                </td>
                                                <td>
                                                    <b-form-input
                                                        v-model="data.dop"
                                                    ></b-form-input>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </b-row>
                            <br/>
                            <b-row v-if="datas.length > 0">
                                <div class="float-right col-12 mt-20">
                                    <button
                                        class="btn btn-primary pull-right"
                                        v-on:click="SubmitData()"
                                    >
                                        <i class="fas fa-list"></i>{{$t('globalTrans.save')}}
                                    </button>
                                </div>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<style scoped>
.opening-balance th,td{
    text-align: center !important;
}
.tableFixHead {
    overflow-y: auto;
    height: 450px;
}
.tableFixHead thead th {
    position: sticky;
    top: 0;
}
.tableFixHead tbody th {
    position: fixed;
}
.tableFixHead th {
    background: #eee;
    font-size: 12px;
    padding: 10px;
    vertical-align: middle;
}
.tableFixHead td {
    padding: 10px;
    font-size: 12px;
    vertical-align: middle;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { openingBlanceList, openingBlanceStore } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
    mixins: [ModalBaseMasterList],
    data () {
        return {
            search: {
                godown_name: null
            },
            datas: [],
            districtList: [],
            upazilaList: []
        }
    },
    computed: {
        fertilizerNameList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('fertilizerConfig.opening_balance') : this.$t('fertilizerConfig.opening_balance')
        },
        divisionList: function () {
            return this.$store.state.commonObj.divisionList
        },
        SalesCenterList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.salesCenterList
        },
        godownInfoList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.godownInfoList
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
        flatpickr('#from_date0', {})
    },
    watch: {
        'search.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        }
    },
    methods: {
        async confirmData () {
            let result = null
            result = await RestApi.postData(seedFertilizerServiceBaseUrl, openingBlanceStore, this.datas)
            if (result.success) {
                this.$toast.success({
                    title: 'Success',
                    message: 'Data saved successfully',
                    color: '#D6E09B'
                })
                this.loadData()
            }
        },
        async SubmitData () {
            this.$swal({
                title: this.$t('globalTrans.recommendentChangeMsg'),
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                focusConfirm: false
            }).then((resultSwal) => {
                if (resultSwal.isConfirmed) {
                    this.confirmData()
                }
            })
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
                if (divisionId) {
                    return districtList.filter(district => district.division_id === divisionId)
                }
            return districtList
        },
        dataChange () {
            this.loadData()
        },
        default () {
            return {
                id: this.rows.length,
                commodity_group_name: '',
                commodity_group_name_bn: '',
                editable: false
            }
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            RestApi.getData(seedFertilizerServiceBaseUrl, openingBlanceList, {}).then(response => {
                if (response.success) {
                    // this.$store.dispatch('setList', this.getRelationalData(response.data))
                    this.datas = this.getRelationalData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getRelationalData (data) {
            const regionList = this.$store.state.commonObj.officeList
            return data.map(item => {
                const regionObject = regionList.find(region => region.value === item.region_id && region.status === 0)
                const regionData = {
                    region_name: regionObject !== undefined ? regionObject.text_en : '',
                    region_name_bn: regionObject !== undefined ? regionObject.text_bn : ''
                }
                const dataObject = item.fertilizers.find(fertilizer => fertilizer.fertilizer_id === 1)
                const mopObject = item.fertilizers.find(fertilizer => fertilizer.fertilizer_id === 2)
                const dopObject = item.fertilizers.find(fertilizer => fertilizer.fertilizer_id === 3)
                const dataData = {
                    tsp: dataObject !== undefined ? dataObject.quantity : '',
                    mop: mopObject !== undefined ? mopObject.quantity : '',
                    dop: dopObject !== undefined ? dopObject.quantity : '',
                    opening_date: dataObject !== undefined ? dataObject.opening_date : ''
                }
                return Object.assign({}, item, regionData, dataData)
            })
        }
    }
}
</script>
